import { graphql, rest } from 'msw'
import { v4 } from 'uuid'
import { cognito } from 'src/auth/mock/cognito'
import { addMedia, getAllMedia } from 'src/media/mockStore'

interface RequestBody {
  testKey: string
  key: string
}

export const handlers = [
  graphql.query('getScreensAndSlideshows', (req, res, ctx) => {
    return res(
      ctx.data({
        screens: [],
      })
    )
  }),
  graphql.query('getSlideshowsQuery', (req, res, ctx) => {
    return res(
      ctx.data({
        slideshows: [],
      })
    )
  }),
  graphql.query('getAllMedia', (req, res, ctx) => {
    return res(ctx.data(getAllMedia()))
  }),
  graphql.query('uploadMediaRequest', (req, res, ctx) => {
    const id = v4()
    return res(
      ctx.data({
        uploadMediaRequest: {
          key: id,
          url: 'https://dummyUploadServer/upload',
          fields: [
            { key: 'testKey', value: 'testValue' },
            { key: 'key', value: id },
          ],
        },
      })
    )
  }),
  rest.post<RequestBody>(
    'https://dummyUploadServer/upload',
    async (req, res, ctx) => {
      const body = await req.json()
      if (body.testKey === 'testValue') {
        setTimeout(() => {
          window.websocketMock.onMediaChanged(addMedia(body.key))
        }, 1000)
        return res(ctx.json({}))
      }
    }
  ),
  rest.post('https://cognito-idp.eu-central-1.amazonaws.com', cognito),
]
