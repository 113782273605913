import { ReactElement } from 'react'
import styled from 'styled-components'
import { device } from 'src/device'

interface CollapsableProps {
  collapse: boolean
}

const Container = styled.div`
  height: 100%;
  background-color: white;
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  min-height: 100%;
`

const SideContainer = styled.div<CollapsableProps>`
  display: flex;

  @media ${device.mobile} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    transition: transform 0.3s ease-in-out;
    transform: translateX(${(props) => (props.collapse ? '0%' : '-100%')});
  }
`

const SideShadow = styled.div`
  box-shadow: inset 10px 0 6px rgb(0, 0, 0, 16%);
  flex: 1;
  max-width: 20%;
  height: 120%;
  position: relative;
  top: -10%;
  display: none;

  @media ${device.mobile} {
    display: block;
  }
`

const SideWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white};
`

const Side = styled.div`
  height: 100vh;
  width: 100%;
  position: sticky;
  top: 0;

  @media ${device.mobile} {
    position: relative;
  }
`

const ContentContainer = styled.div`
  flex: 1;
  position: relative;

  @media ${device.mobile} {
    width: 100%;
  }
`

interface ContentWrapperProps {
  showLeftPadding?: boolean
}

const ContentWrapper = styled.div<ContentWrapperProps>`
  padding: 15px;
  ${(props) => (props.showLeftPadding ? '' : 'padding-left: 0')};
  width: 100%;
  display: flex;
  height: 100%;

  @media ${device.mobile} {
    padding: 0;
    width: 100vw;
  }
`

const Content = styled.div`
  border-radius: 30px;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  padding: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media ${device.mobile} {
    border-radius: 0;
  }
`

export interface LayoutProps {
  children: ReactElement
  sidebar: ReactElement
  showSidebar?: boolean
  withoutMenu?: boolean
  onHideSidebar?: () => void
}

export function Layout({
  children,
  sidebar,
  showSidebar = false,
  withoutMenu = false,
  onHideSidebar,
}: LayoutProps): ReactElement {
  return (
    <Container>
      <Wrapper>
        {!withoutMenu && (
          <SideContainer collapse={showSidebar}>
            <SideWrapper>
              <Side>{sidebar}</Side>
            </SideWrapper>
            <SideShadow onClick={onHideSidebar} />
          </SideContainer>
        )}
        <ContentContainer>
          <ContentWrapper showLeftPadding={withoutMenu}>
            <Content>{children}</Content>
          </ContentWrapper>
        </ContentContainer>
      </Wrapper>
    </Container>
  )
}
