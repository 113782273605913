import { useCallback, useRef, useState } from 'react'
import { faRetweetAlt, faSignIn } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useAuth } from 'src/auth/useAuth'
import { useScrollToTop } from 'src/hooks/useScrollToTop'
import { AuthHistoryState } from './AuthHistoryState'
import { AuthPage } from './AuthPage'
import { FormField } from './FormField'

export function LoginScreen() {
  useScrollToTop()
  const { t } = useTranslation()
  const { login } = useAuth()

  const newPasswordCb = useRef<(password: string) => Promise<void>>()
  const [needToChangePassword, setNeedToChangePassword] = useState(false)

  const onSubmit = useCallback(
    async ({ username, password }: { username: string; password: string }) => {
      await login(username, password, (setNewPasswordCb) => {
        newPasswordCb.current = setNewPasswordCb
        setNeedToChangePassword(true)
      })
    },
    [login]
  )

  const onSubmitNewPassword = useCallback(
    async ({ password }: { password: string }) => {
      if (newPasswordCb.current) {
        await newPasswordCb.current(password)
      }
    },
    []
  )

  const location = useLocation()

  const state = location.state as AuthHistoryState | undefined

  let description

  if (state?.passwordChanged) {
    description = t('auth.login.passwordChanged')
  }

  return needToChangePassword ? (
    <AuthPage
      key="new-password"
      title={t('auth.newPassword.title')}
      description={t('auth.newPassword.description')}
      error={t('auth.newPassword.failed')}
      defaultField="password"
      onSubmit={onSubmitNewPassword}
      submitButtonTitle={t('auth.newPassword.submit')}
      submitButtonIcon={faRetweetAlt}
      submitButtonTestId="new-password-button"
    >
      <FormField
        name="password"
        type="password"
        label={t('auth.newPassword.password.label')}
        requiredMessage={t('auth.newPassword.passwordIsRequired')}
        data-test="new-password-input"
      />
    </AuthPage>
  ) : (
    <AuthPage
      key="login"
      title={t('auth.login.title')}
      description={description}
      error={t('auth.login.failedLogin')}
      defaultField="username"
      onSubmit={onSubmit}
      submitButtonTitle={t('auth.login.submit')}
      submitButtonIcon={faSignIn}
      submitButtonTestId="sign-in-sign-in-button"
    >
      <FormField
        name="username"
        type="email"
        label={t('auth.login.email.label')}
        requiredMessage={t('auth.login.emailIsRequired')}
        data-test="username-input"
      />
      <FormField
        name="password"
        type="password"
        label={t('auth.login.password.label')}
        requiredMessage={t('auth.login.passwordIsRequired')}
        linkTitle={t('auth.login.forgotPassword')}
        link="/forget-password"
        data-test="sign-in-password-input"
      />
    </AuthPage>
  )
}
