import { useCallback, useEffect, useState } from 'react'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Box } from 'src/elements/Box'
import { Button } from 'src/elements/Button'
import { TitleH2 } from 'src/elements/TitleH2'
import { FormGroup } from 'src/elements/form/FormGroup'
import { Input } from 'src/elements/form/Input'
import { Label } from 'src/elements/form/Label'
import { CenteredContent } from 'src/layout/CenteredContent'

const StyledTitle = styled(TitleH2)`
  margin-bottom: 40px;
`

const StyledDescription = styled.p`
  margin-bottom: 40px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.blue};
  text-align: center;
`

const StyledBox = styled(Box)`
  width: 100%;
`
const Error = styled.div`
  color: ${(props) => props.theme.colors.red};
  font-size: 14px;
  margin-top: 20px;
`

export function Onboarding() {
  const form = useForm()
  const {
    setFocus,
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = form
  const { t } = useTranslation()
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    setFocus('name')
  }, [setFocus])

  const onSubmit = useCallback(
    async (fields: Record<string, string>) => {
      console.log('onSubmit', fields)
      setError('Error message')
    },
    [setError]
  )

  return (
    <CenteredContent withLogo>
      <StyledTitle>{t('onboarding.title')}</StyledTitle>
      <StyledDescription>{t('onboarding.desc')}</StyledDescription>
      <StyledBox>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label>{t('onboarding.form.org.name.label')}</Label>
              <Input
                placeholder={t('onboarding.form.org.name.placeholder')}
                {...register('name', { required: true })}
              />
              <Button
                as="button"
                type="submit"
                label={t('onboarding.form.org.submit')}
                icon={faPlusCircle}
                fullWidth
                data-test="onboarding-new-org"
                disabled={isSubmitting}
              />
              {error && <Error>{error}</Error>}
            </FormGroup>
          </form>
        </FormProvider>
      </StyledBox>
    </CenteredContent>
  )
}
