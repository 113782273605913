import { SetupWorker, graphql, rest, setupWorker } from 'msw'
import { MockServer, mockWebsocket } from 'src/apiMockHelper'
import {
  setForceChangePassword,
  setForgetPasswordSuccess,
  setLoginSuccess,
  setNewPasswordSuccess,
  setResetPasswordSuccess,
} from 'src/auth/mock/cognito'
import { handlers } from './handlers'

declare global {
  interface Window {
    msw: {
      worker: SetupWorker
      graphql: typeof graphql
      rest: typeof rest
    }
    websocketMock: MockServer
    setForgetPasswordSuccess: typeof setForgetPasswordSuccess
    setLoginSuccess: typeof setLoginSuccess
    setResetPasswordSuccess: typeof setResetPasswordSuccess
    setForceChangePassword: typeof setForceChangePassword
    setNewPasswordSuccess: typeof setNewPasswordSuccess
  }
}

export function initalizeMock() {
  const worker = setupWorker(...handlers)
  window.msw = {
    worker,
    graphql,
    rest,
  }

  window.websocketMock = mockWebsocket('wss://dummy/graphql')
  window.setForgetPasswordSuccess = setForgetPasswordSuccess
  window.setLoginSuccess = setLoginSuccess
  window.setResetPasswordSuccess = setResetPasswordSuccess
  window.setForceChangePassword = setForceChangePassword
  window.setNewPasswordSuccess = setNewPasswordSuccess

  return worker
}
