import { ApolloProvider } from '@apollo/client'
import { Router } from './Router'
import { Config } from './config'
import { useCreateApolloClient } from './hooks/useCreateApolloClient'

interface Props {
  config: Config
}

export function ConfiguredApp({ config }: Props) {
  const apolloClient = useCreateApolloClient(config)

  return (
    <ApolloProvider client={apolloClient}>
      <Router />
    </ApolloProvider>
  )
}
