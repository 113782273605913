import { BrowserRouter } from 'react-router-dom'
import { Page } from './layout/Page'

export function Router() {
  return (
    <BrowserRouter>
      <Page />
    </BrowserRouter>
  )
}
