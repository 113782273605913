import { useCallback, useEffect, useState } from 'react'
import { HubCallback } from '@aws-amplify/core'
import { Auth, Hub } from 'aws-amplify'

enum ChallengeName {
  NewPasswordRequired = 'NEW_PASSWORD_REQUIRED',
}

export function useAuth() {
  const logout = useCallback(async () => {
    await Auth.signOut()
  }, [])

  const login = useCallback(
    async (
      username: string,
      password: string,
      onNewPasswordRequired: (
        setNewPassword: (password: string) => Promise<void>
      ) => void
    ) => {
      const user = await Auth.signIn(username, password)

      if (user.challengeName === ChallengeName.NewPasswordRequired) {
        onNewPasswordRequired(async (password: string) => {
          await Auth.completeNewPassword(user, password, {})
        })
      }
    },
    []
  )

  const newPassword = useCallback(async () => {
    const session = Auth.currentSession()
    console.log(session)
    //
  }, [])

  const forgetPassword = useCallback(async (username: string) => {
    await Auth.forgotPassword(username)
  }, [])

  const resetPassword = useCallback(
    async (username: string, code: string, password: string) => {
      await Auth.forgotPasswordSubmit(username, code, password)
    },
    []
  )

  const [isLoggedIn, setLoggedIn] = useState<boolean | null>(null)

  useEffect(() => {
    let isUnmounted = false
    ;(async () => {
      try {
        await Auth.currentAuthenticatedUser()
        if (!isUnmounted) {
          setLoggedIn(true)
        }
      } catch (e) {
        if (!isUnmounted) {
          setLoggedIn(false)
        }
      }
    })()

    return () => {
      isUnmounted = true
    }
  }, [])

  useEffect(() => {
    let isUnmounted = false

    const listener: HubCallback = (data) => {
      console.log(data.payload.event)
      if (isUnmounted) {
        return
      }
      switch (data.payload.event) {
        case 'signIn':
          setLoggedIn(true)
          break
        case 'signOut':
          setLoggedIn(false)
          break
      }
    }

    Hub.listen('auth', listener)

    return () => {
      Hub.remove('auth', listener)
      isUnmounted = true
    }
  }, [])

  return {
    newPassword,
    forgetPassword,
    resetPassword,
    login,
    logout,
    isLoggedIn,
  }
}
