import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from 'src/auth/useAuth'
import { useToggleOpener } from 'src/hooks/useToggleOpener'
import { Layout } from './Layout'
import { Main } from './Main'
import { SideMenu } from './SideMenu'

export function Page() {
  const [showSidebar, toggleSidebar, closeSidebar] = useToggleOpener()

  const { pathname } = useLocation()
  useEffect(() => {
    closeSidebar()
  }, [pathname, closeSidebar])

  const { isLoggedIn } = useAuth()

  return (
    <Layout
      withoutMenu={!isLoggedIn}
      showSidebar={showSidebar}
      onHideSidebar={closeSidebar}
      sidebar={<SideMenu onCloseSidebar={closeSidebar} />}
    >
      <Main onToggleSidebar={toggleSidebar} />
    </Layout>
  )
}
